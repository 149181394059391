<template>
  <v-row>
    <v-col cols="12" class="d-none d-md-block">
      <v-card dark color="primary">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel class="primary">
            <v-expansion-panel-header class="pl-2 pr-7 py-0">
              <v-card-title>
                Filtros de busca: Depósito
                <v-card-text class="pa-0" v-if="primeiraBusca">
                  <v-col cols="12" class="px-0">
                    <span v-if="!carregando">{{produtos.total}} resultados encontrados.</span>
                  </v-col>
                  <v-col cols="12" class="px-2" >
                    <v-row v-if="!carregando">
                      <span>
                        <v-chip label small class="success ma-1">Filial {{ filtrosUsados.idempresa }}</v-chip>
                      </span>
                      <span>
                        <v-chip v-show="filtrosUsados.flagestoque" label small class="success ma-1"> SOMENTE PRODUTOS COM ESTOQUE </v-chip>
                      </span>
                      <span>
                        <v-chip v-show="filtrosUsados.tipo=='G'" label small class="success ma-1">{{filtrosUsados.dtini | formataData}} á {{filtrosUsados.dtfim | formataData}}</v-chip>
                        <v-chip v-show="filtrosUsados.tipo=='P'" label small class="success ma-1"> Somente Promoções </v-chip>
                      </span>
                      <span v-if="filtrosUsados.busca">
                        <v-chip @click:close="limpaFiltros('produto', filtrosUsados.busca)" label small close class="success ma-1"> Filtrado por: "{{filtrosUsados.busca}}"</v-chip>
                      </span>
                      <span v-for="(item) in filtrosUsados.secoes" :key="item">
                        <v-chip @click:close="limpaFiltros('secoes', item)" label close small class="success ma-1">{{filtrosEtiquetas.find(v => v.idsecao == item).secao}}</v-chip>
                      </span>
                      <span v-for="(item) in filtrosUsados.fabricantes" :key="item">
                        <v-chip @click:close="limpaFiltros('fabricantes', item)" label close small class="success ma-1">{{filtrosEtiquetas.find(v => v.idfabricante == item).fabricante}}</v-chip>
                      </span>
                      <span v-for="(item) in filtrosUsados.promocoes" :key="item">
                        <v-chip v-show="filtrosUsados.tipo=='P'" @click:close="limpaFiltros('promocoes', item)" label close small class="success ma-1">{{filtrosEtiquetas.find(v => v.idpromocao == item).promocao}}</v-chip>
                      </span>
                    </v-row>
                    <v-row v-else>
                      <v-progress-circular
                          class="mt-1 mr-1"
                          indeterminate
                          color="white"
                          :size="12"
                      ></v-progress-circular>
                      <span class="text-caption font-italic">Carregando...</span>
                    </v-row>
                  </v-col>
                </v-card-text>
              </v-card-title>
            </v-expansion-panel-header>
            <v-divider/>
            <v-expansion-panel-content class="ml-n6 mr-n6 pt-6 pb-0">
              <v-card-text class="pr-5 py-0">
                <v-row>
                  <v-col cols="4">
                    <v-select
                        label="Filial"
                        :items="filiaisFiltro"
                        item-text="filial"
                        item-value="idfilial"
                        v-model="buscaPersonalizada.idempresa"
                        dense
                        hide-details
                        outlined
                        @change="listarFiltros('data')"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="4" v-show="buscaPersonalizada.tipo=='G'">
                    <InputDatePicker
                        @input="listarFiltros('data')"
                        v-model="buscaPersonalizada.dtini"
                        label="Período inicial"
                        :outlined="true"
                        :dense="true"
                    />
                  </v-col>
                  <v-col cols="4" v-show="buscaPersonalizada.tipo=='G'">
                    <InputDatePicker
                        v-model="buscaPersonalizada.dtfim"
                        label="Período final"
                        :outlined="true"
                        :dense="true"
                        @input="listarFiltros('data')"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field hide-details ref="refProduto" v-model="buscaPersonalizada.busca" outlined dense label="Código ou descrição do produto"></v-text-field>
                  </v-col>
                  <v-col cols="4" v-if="true">
                    <v-autocomplete
                        label="Seções"
                        :items="obterValoresUnicos(filtrosEtiquetas, ['idsecao','secao'], {'idpromocao': buscaPersonalizada.promocoes, 'idfabricante': buscaPersonalizada.fabricantes})"
                        item-text="secao"
                        item-value="idsecao"
                        v-model="buscaPersonalizada.secoes"
                        dense
                        multiple
                        outlined
                        hide-details
                        clearable
                        ref="refSecao"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip
                            v-if="index === 0"
                            :key="item.idsecao"
                            color="success"
                            class="ma-1"
                            small
                        >
                          <span class="text-caption">{{ item.secao }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="white--text text-caption">
                            (+{{ buscaPersonalizada.secoes.length - 1 }})
                          </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" v-if="true">
                    <v-autocomplete
                        label="Fabricantes"
                        :items="obterValoresUnicos(filtrosEtiquetas, ['idfabricante','fabricante'], {'idpromocao':buscaPersonalizada.promocoes,'idsecao':buscaPersonalizada.secoes})"
                        item-text="fabricante"
                        item-value="idfabricante"
                        v-model="buscaPersonalizada.fabricantes"
                        outlined
                        dense
                        hide-details
                        multiple
                        clearable
                        ref="refFabricante"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip
                            v-if="index === 0"
                            :key="item.idfabricante"
                            color="success"
                            class="ma-1"
                            small
                        >
                          {{ item.fabricante }}
                        </v-chip>
                        <span v-if="index === 1" class="white--text text-caption">
                                (+{{ buscaPersonalizada.fabricantes.length - 1 }})
                              </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" class="py-1" v-show="buscaPersonalizada.tipo=='G'">
                    <v-switch hide-details class="mt-0" color="success" v-model="buscaPersonalizada.flagestoque" label="Somente produtos com estoque"></v-switch>
                  </v-col>
                  <v-col cols="12" class="pa-0 pt-2"><v-divider class="white--text" /></v-col>
                  <v-col cols="6">
                    <v-btn :disabled="carregando || (buscaPersonalizada.tipo=='P' && buscaPersonalizada.promocoes.length==0)" :loading="carregando" class="success" @click="listarProdutosPersonalizados(0), panel=1" width="100%">
                      Buscar Produtos
                      <v-icon class="ml-1">mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn :disabled="carregando" :loading="carregando" light width="100%" @click="limpaFiltros">
                      Limpar Filtros
                      <v-icon class="ml-1">mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12" class="d-md-none">
      <v-row>
        <v-col cols="12">
          <v-stepper v-if="true" class="primary pb-7" dark v-model="e6" vertical>
            <v-stepper-step :complete="e6>1" :editable="e6>1"  step="1" class="py-3 my-3"> Loja </v-stepper-step>
            <v-stepper-content step="1" class="pa-0">
              <v-row align="start"  class="ml-2 mr-12">
                <v-col cols="12" class="pb-0">
                  <v-select
                      :items="filiaisFiltro"
                      item-text="filial"
                      item-value="idfilial"
                      v-model="buscaPersonalizada.idempresa"
                      dense
                      hide-details
                      outlined
                      @change="listarFiltros('data')"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" class="text-right pb-6">
                  <v-btn disabled small text color="white" @click="e6 = 1">Voltar</v-btn>
                  <v-btn small color="success" @click="e6 = 2">Próximo</v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-step :complete="e6>2" :editable="e6>2" step="2" class="py-3 my-3"> Tipo de busca</v-stepper-step>
            <v-stepper-content step="2" class="pl-0 py-0">
              <v-row align="end" class="mt-1 ml-2">
                <v-col cols="12" class="pa-0 pt-2">
                  <v-radio-group hide-details v-model="buscaPersonalizada.tipo" class="ma-0 pa-0" row>
                    <v-col cols="12" class="py-0 pb-3">
                      <v-radio color="success" label="Período / Alteração" value="G" @click="listarFiltros('geral')"></v-radio>
                    </v-col>
                    <v-col cols="12" class="py-0 pb-3">
                      <v-radio color="success" label="Produto" value="E" @click="listarFiltros('geral')"></v-radio>
                    </v-col>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" class="text-right pb-6">
                  <v-btn small text color="white" @click="e6 = 1">Voltar</v-btn>
                  <v-btn small color="success" @click="e6 = 3">Próximo</v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-step :complete="e6>3" :editable="e6>3" step="3" class="py-3 my-3"> {{ buscaPersonalizada.tipo=='G' ? 'Período de alteração' : 'Busca por produto' }} </v-stepper-step>
            <v-stepper-content step="3" class="pl-0 py-0">
              <v-row v-show="buscaPersonalizada.tipo=='G'" align="end" class="mt-1 ml-2">
                <v-col cols="12" class="pt-0">
                  <InputDatePicker
                      @input="listarFiltros('data')"
                      :disabled="carregando"
                      v-model="buscaPersonalizada.dtini"
                      label="Período inicial"
                      :outlined="true"
                      :dense="true"
                  />
                </v-col>
                <v-col cols="12" class="pb-0">
                  <InputDatePicker
                      @input="listarFiltros('data')"
                      :disabled="carregando"
                      v-model="buscaPersonalizada.dtfim"
                      label="Período final"
                      :outlined="true"
                      :dense="true"
                  />
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-switch hide-details class="mt-0" color="success" v-model="buscaPersonalizada.flagestoque" label="Somente produtos com estoque"></v-switch>
                </v-col>
                <v-col cols="12" class="text-right pb-6">
                  <v-btn small text color="white" @click="e6 = 2">Voltar</v-btn>
                  <v-btn small color="success" @click="e6 = 4">Próximo</v-btn>
                </v-col>
              </v-row>
              <v-row v-show="buscaPersonalizada.tipo=='E'" align="end" class="mt-1 ml-2">
                <v-col cols="12" class="pt-0">
                  <v-text-field class="mb-n6" :rules="[tipoRule2]" v-model="buscaPersonalizada.busca" outlined dense label="Código ou descrição do produto"></v-text-field>
                </v-col>
                <v-col cols="12" class="text-right pb-6">
                  <v-btn small text color="white" @click="e6 = 2">Voltar</v-btn>
                  <v-btn small color="success" @click="e6 = 4, listarFiltros('data')" :disabled="buscaPersonalizada.busca.length < 3">Próximo</v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-step :complete="e6>4" :editable="e6>4" step="4" class="py-3 my-3"> Seções </v-stepper-step>
            <v-stepper-content step="4" class="pl-0 py-0">
              <v-row align="end" class="d-block mt-1 ml-2">
                <v-col cols="12" class="py-0">
                  <v-select
                      label="Seções"
                      :items="obterValoresUnicos(filtrosEtiquetas, ['idsecao','secao'], {'idpromocao': buscaPersonalizada.promocoes, 'idfabricante': buscaPersonalizada.fabricantes})"
                      item-text="secao"
                      item-value="idsecao"
                      v-model="buscaPersonalizada.secoes"
                      dense
                      multiple
                      outlined
                      hide-details
                      clearable
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip
                          v-if="index === 0"
                          :key="item.idsecao"
                          color="success"
                          class="ma-1"
                          small
                      >
                        <span class="text-caption">{{ item.secao }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="white--text text-caption">
                            (+{{ buscaPersonalizada.secoes.length - 1 }})
                          </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" class="text-right pb-6">
                  <v-btn small text color="white" @click="e6 = 3, buscaPersonalizada.secoes=[], filtrosUsados.secoes=[]">Voltar</v-btn>
                  <v-btn small color="success" @click="e6 = 5">Próximo</v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-step :complete="e6>5" :editable="e6>5" step="5" class="py-3 my-3"> Fabricantes </v-stepper-step>
            <v-stepper-content step="5" class="pl-0 py-0">
              <v-row align="end" class="d-block mt-1 ml-2">
                <v-col cols="12" class="py-0">
                  <v-select
                      label="Fabricantes"
                      :items="obterValoresUnicos(filtrosEtiquetas, ['idfabricante','fabricante'], {'idpromocao':buscaPersonalizada.promocoes,'idsecao':buscaPersonalizada.secoes})"
                      item-text="fabricante"
                      item-value="idfabricante"
                      v-model="buscaPersonalizada.fabricantes"
                      outlined
                      dense
                      hide-details
                      multiple
                      clearable
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip
                          v-if="index === 0"
                          :key="item.idfabricante"
                          color="success"
                          class="ma-1"
                          small
                      >
                        {{ item.fabricante }}
                      </v-chip>
                      <span v-if="index === 1" class="white--text text-caption">
                                (+{{ buscaPersonalizada.fabricantes.length - 1 }})
                              </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" class="text-right pb-6">
                  <v-btn small text color="white" @click="e6 = 4, buscaPersonalizada.fabricantes=[], filtrosUsados.fabricantes=[]" >Voltar</v-btn>
                  <v-btn small color="success" @click="e6 = 6">Próximo</v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-step step="6" class="py-3 my-3"> Finalização </v-stepper-step>
            <v-stepper-content step="6" class="px-0 mx-4 pt-0 pb-1 ">
              <v-divider class="pb-3" />
              <v-col cols="12" class="pa-0 mb-6">
                <fieldset>
                  <legend>Filtros de pesquisa</legend>
                  <span>
                    <v-chip label x-small class="success ma-1">Filial {{ buscaPersonalizada.idempresa }}</v-chip>
                  </span>
                  <span>
                    <v-chip v-show="buscaPersonalizada.flagestoque" label x-small class="success ma-1"> SOMENTE PRODUTOS COM ESTOQUE </v-chip>
                  </span>
                  <span>
                      <v-chip v-show="buscaPersonalizada.tipo=='G'" label x-small class="success ma-1">{{buscaPersonalizada.dtini | formataData}} á {{buscaPersonalizada.dtfim | formataData}}</v-chip>
                      <v-chip v-show="buscaPersonalizada.tipo=='P'" label x-small class="success ma-1"> Somente Promoções </v-chip>
                  </span>
                  <span v-for="(item) in buscaPersonalizada.promocoes" :key="item">
                      <v-chip label x-small  class="success ma-1">{{filtrosEtiquetas.find(v => v.idpromocao == item).promocao}}</v-chip>
                  </span>
                  <span v-for="(item) in buscaPersonalizada.fabricantes" :key="item">
                      <v-chip label x-small  class="success ma-1">{{filtrosEtiquetas.find(v => v.idfabricante == item).fabricante}}</v-chip>
                  </span>
                  <span v-for="(item) in buscaPersonalizada.secoes" :key="item">
                      <v-chip label x-small  class="success ma-1">{{filtrosEtiquetas.find(v => v.idsecao == item).secao}}</v-chip>
                  </span>
                  <span v-if="buscaPersonalizada.busca">
                      <v-chip label x-small  class="success ma-1"> Filtrar por: "{{buscaPersonalizada.busca}}"</v-chip>
                  </span>
                </fieldset>
              </v-col>
              <v-row align="end">
                <v-col cols="6" class="pr-2">
                  <v-btn small :disabled="carregando" :loading="carregando" light width="100%" @click="e6 = 5">
                    Voltar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="pl-1">
                  <v-btn small :disabled="carregando" :loading="carregando" light width="100%" @click="limpaFiltros('geral'), e6 = 1">
                    Recomeçar
                    <v-icon class="ml-1">mdi-arrow-u-up-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" class="mt-n3">
                  <v-btn small :disabled="carregando" :loading="carregando" class="success" @click="listarProdutosPersonalizados(0), panel=1" width="100%">
                    Buscar Produtos
                    <v-icon class="ml-1">mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <span class="d-none d-md-block">Produtos</span>
          <v-spacer />
          <v-btn
              :disabled="selected.length == 0 || carregando"
              elevation="0"
              class="mx-3"
              color="primary"
              @click="selected = []"
          >
            <span class="d-none d-md-block">Limpar produtos selecionados</span>
            <v-icon class="ml-0 ml-md-2">mdi-close</v-icon>
          </v-btn>
          <v-badge color="green" overlap :content="qtdEtiquetas" :value="qtdEtiquetas" offset-x="27">
            <v-btn class="mr-3" :disabled="qtdEtiquetas == 0 || carregando" elevation="0" color="primary" @click="dialogProdutosSelecionado = true">
              <span class="d-none d-md-block">Imprimir produtos Selecionados</span>
              <v-icon class="ml-0 ml-md-2">mdi-barcode</v-icon>
            </v-btn>
          </v-badge>
          <v-tooltip bottom color="error" class="d-none d-md-block">
            <template v-slot:activator="{ on }">
              <v-btn
                  v-on="on"
                  :disabled="produtos.lista == 0 || carregando || selected.length>0"
                  elevation="0"
                  color="primary"
                  @click="dialogConfirma=true"
              >
                <span class="d-none d-md-block">Imprimir geral</span>
                <v-icon size="22" class="ml-0 ml-md-2">mdi-printer</v-icon>
              </v-btn>
            </template>
            <span><v-icon size="20" color="white" class="mr-1 mt-n1">mdi-alert-circle-outline</v-icon>A impressão será realizada com base no resultado da pesquisa ({{produtos.total}}  produtos)</span>
          </v-tooltip>
        </v-card-title>
        <v-divider v-if="!carregando"/>
        <v-progress-linear indeterminate absolute :active="carregando" />
        <v-card-text class="py-0">
          <v-data-table
              :headers="[
                    { text: 'Qtd. Etiqueta', value: 'quantidade', align:'left',  sortable: false},
                    { text: 'Cód. Barras', value: 'codigobarra', align:'left',  sortable: false},
                    { text: 'Cód. Produto', value: 'idsubproduto', align:'left',  sortable: false},
                    { text: 'Produto', value: 'produto', align:'left',  sortable: false},
                    { text: 'Embalagem', value: 'embalagem', align:'left',  sortable: false},
                ]"
              :items="produtos.lista"
              show-select
              v-model="selected"
              item-key="compositeKey"
              :search="search"
              hide-default-footer
              :items-per-page="-1"
              class="pb-2"
          >
            <template v-if="produtos.total>100" v-slot:top>
              <div class="my-2" >
                <Paginacao
                    :total="produtos.total"
                    @atualizar="listarProdutosPersonalizados"
                    :carregando="carregando"
                    :pg="pgSelecionada"
                    :pgLimitInt="100"
                />
              </div>
              <v-divider />
            </template>
            <template v-if="carregando" v-slot:item="{item}">
              <tr v-if="carregando || !item">
                <td colspan="100%"><v-skeleton-loader class="white" type="table-row" /></td>
              </tr>
            </template>
            <template v-slot:item.quantidade="{ item }">
              <v-text-field
                  hide-details
                  style="width: 110px;"
                  reverse
                  dense
                  type="number"
                  :disabled="!isItemSelected(item) || carregando"
                  :append-icon="!isItemSelected(item)? 'mdi-alert-circle' : ''"
                  v-model.number="item.quantidade"
                  @input="verificaQuantidade(item)"
              ></v-text-field>
            </template>
            <template v-slot:item.codigobarra="{ item }">
              {{  item.codigobarra }}
            </template>
            <template v-slot:item.idsubproduto="{ item }">
              {{  item.idsubproduto }}
            </template>
            <template v-slot:item.produto="{ item }">
              {{  item.produto }}
            </template>
            <template v-slot:item.embalagem="{ item }">
              {{  item.embalagem }}
            </template>
          </v-data-table>
          <v-divider v-if="produtos.lista.length>0" />
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="dialogConfirma" width="750px" persistent>
      <v-card>
        <v-card-title>
          Confirmação
        </v-card-title>
        <v-divider/>
        <v-card-text class="py-5">
          A geração da(s) etiquetas terá um total de <strong>{{produtos.total}}</strong> produto(s), sendo dividido em <strong>{{Math.ceil(produtos.total / 672)}}</strong> arquivo(s) PDF. <br/>
          Confirma o download do(s) arquivo(s)?
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-col cols="5" class="px-2"><v-btn class="error" @click="dialogConfirma= false" width="100%">Cancelar</v-btn></v-col>
          <v-col cols="7" class="px-2 d-none d-sm-block"><v-btn class="primary" @click="geraEtiquetasGeral()" width="100%">Gerar Etiquetas <v-icon class="ml-2">mdi-download</v-icon></v-btn></v-col>
          <v-col cols="7" class="px-2 d-sm-none"><v-btn class="primary" @click="geraEtiquetasGeral()" width="100%">Gerar <v-icon class="ml-2">mdi-download</v-icon></v-btn></v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProdutosSelecionado" fullscreen>
      <v-card>
        <v-card-title>
          Produtos Selecionados
          <v-spacer/>
          <v-btn icon @click="dialogProdutosSelecionado = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
              :headers="[
                    { text: 'Qtd. Etiqueta', value: 'quantidade', align:'left'},
                    { text: 'Cód. Barras', value: 'codigobarra', align:'left'},
                    { text: 'Cód. Produto', value: 'idsubproduto', align:'left'},
                    { text: 'Produto', value: 'produto', align:'left'},
                    { text: 'Embalagem', value: 'embalagem', align:'left'},
                ]"
              :items="selected"
              height="calc(100vh - 250px)"
              fixed-header
              show-select
              v-model="selected"
              item-key="compositeKey"
              :search="search"
              hide-default-footer
              :items-per-page="-1"
              item
              class="pb-2"
          >
            <template v-slot:top>
              <v-text-field
                  v-model="search"
                  label="Buscar produto na lista de seleção"
                  placeholder="codigo ou descrição"
                  class="pb-3"
                  outlined
                  dense
                  hide-details
              ></v-text-field>
              <v-divider/>
            </template>
            <template v-slot:item.quantidade="{ item }">
              <v-text-field
                  hide-details
                  style="width: 110px;"
                  reverse
                  dense
                  type="number"
                  :disabled="!isItemSelected(item) || carregando"
                  :append-icon="!isItemSelected(item)? 'mdi-alert-circle' : ''"
                  v-model.number="item.quantidade"
                  @input="verificaQuantidade(item)"
              ></v-text-field>
            </template>
            <template v-slot:item.codigobarra="{ item }">
              {{  item.codigobarra }}
            </template>
            <template v-slot:item.idsubproduto="{ item }">
              {{  item.idsubproduto }}
            </template>
            <template v-slot:item.produto="{ item }">
              {{  item.produto }}
            </template>
            <template v-slot:item.embalagem="{ item }">
              {{  item.embalagem }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="carregando" class="primary" @click="carregandoPdf= true, geraEtiquetas()" width="100%">
            Gerar Etiquetas <v-icon class="ml-2">mdi-download</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="carregandoPdf" persistent overlay-opacity="0.7" width="500px">
      <v-card color="primary" dark>
        <v-card-text class="pa-5">
          <span>{{ carregandoPdfMensagem }}...</span>
          <v-progress-linear stream color="white"  buffer-value="0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-btn
        v-if="isVisible2"
        class="d-md-none mb-12"
        fixed
        bottom
        right
        :disabled="qtdEtiquetas == 0 || carregando"
        elevation="0"
        color="primary"
        @click="dialogProdutosSelecionado = true"
        fab
    >
      <v-badge color="green" :content="qtdEtiquetas" :value="qtdEtiquetas">
        <v-icon size="30" class="ml-0 ml-md-3">mdi-barcode</v-icon>
      </v-badge>
    </v-btn>
    <v-btn
        class="d-none d-md-block"
        v-if="isVisible"
        fab
        fixed
        bottom
        right
        color="primary"
        @click="scrollToTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import InputDatePicker from "../Widgets/InputDatePicker";
import Paginacao from "@/Components/Widgets/Paginacao.vue";
import html2pdf from "html2pdf.js";

export default {
  name: "EtiquetasLojaGerarPdf",
  mixins: [mixinFilial],
  components: { Paginacao, InputDatePicker },
  data: () => ({
    produtosHistorico: [],

    fabComplete: [],
    secComplete: [],

    isVisible:false,
    isVisible2:false,
    dialogConfirma: false,
    carregandoPdfMensagem: '',
    qtdEtiquetas: 0,
    show:false,
    dialogIframe: false,
    pdfUrl:null,
    e6: 1,
    carregandoPdf: false,
    limite: 30,
    primeiraBusca:false,
    pgSelecionada: 0,
    panel: 0,
    dialogProdutosSelecionado: false,
    search: '',
    selected: [],
    selectedPrint: [],
    produtos: { lista: [], total: 0 },
    produtosGeral: { lista: [], total: 0 },
    filtrosEtiquetas: [],

    buscaPersonalizada:{
      busca: '',
      fabricantes:[],
      secoes:[],
      promocoes:[],
      idempresa: null,
      dtini: `${new Date().getFullYear()}-01-01`,
      dtfim: `${new Date(new Date())
          .toLocaleDateString("pt-BR")
          .split("/")
          .reverse()
          .join("-")}`,
      tipo: 'G',
      flagestoque: true,
    },

    filtrosUsados: {
      busca: "",
      tipo: 'G',
      fabricantes: [],
      secoes: [],
      promocoes: [],
      dtini: `${new Date().getFullYear()}-${
          (new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
          ).getMonth() +
          1 <
          10
              ? "0"
              : "") +
          (new Date().getMonth() + 1)
      }-01`,
      dtfim: `${new Date(new Date())
          .toLocaleDateString("pt-BR")
          .split("/")
          .reverse()
          .join("-")}`,
      idempresa: null,
      flagestoque: null,
    },

    carregando2:false,
    carregando: false,
  }),
  computed: {
    ...mapState(["backendUrl", "usuario", "pgLimit"]),
    tipoRule() {
      return (value) => value.length > 0 || '*Campo obrigatório';
    },
    tipoRule2() {
      return (value) => value.length > 2 || '*Campo deve ter no mínimo 3 caracteres';
    },
    filiaisFiltro() {
      if (this.filiais.lista) {
        return this.filiais.lista.filter((v) => {
          return (
              v.idfilial != 6 &&
              v.idfilial != 33 &&
              v.idfilial != 37
          );
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    obterValoresUnicos(arrayGeral, propriedades, filtrosConfig) {
      // Filtrar o array geral com base nos filtros configurados
      let arrayFiltrado = arrayGeral;

      // Iterar sobre cada filtro configurado e aplicar a filtragem
      Object.keys(filtrosConfig).forEach(tipo => {
        if (filtrosConfig[tipo].length > 0) {
          arrayFiltrado = arrayFiltrado.filter(item => filtrosConfig[tipo].includes(item[tipo]));
        }
      });

      // Agrupar os dados pelas propriedades especificadas
      const agrupados = this.agruparPorPropriedades(arrayFiltrado, propriedades);

      // Transformar o objeto agrupado em um array de objetos únicos
      const resultado = Object.values(agrupados).map(grupo => {
        const [primeiroItem] = grupo; // Usar o primeiro item do grupo como representativo
        return propriedades.reduce((obj, prop) => {
          obj[prop] = primeiroItem[prop]; // Adicionar cada propriedade ao objeto resultante
          return obj;
        }, {});
      });

      return resultado;
    },

    agruparPorPropriedades(array, propriedades) {
      return array.reduce((acumulador, item) => {
        const chave = propriedades.map(prop => item[prop]).join('|'); // Criar uma chave única combinando os valores das propriedades
        if (!acumulador[chave]) {
          acumulador[chave] = [];
        }
        acumulador[chave].push(item);
        return acumulador;
      }, {});
    },

    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    checkScrollPosition() {
      this.isVisible = window.scrollY > 200; // Exibir o botão após rolar 200 pixels
      this.isVisible2 = window.scrollY > 550; // Exibir o botão após rolar 200 pixels
    },

    limpaFiltros(tipo, item){
      this.produtos.lista = [];
      this.produtos.total = 0;
      this.panel = 0; // Expandir o painel
      var index;
      switch (tipo) {
        case "promocoes":
          index = this.filtrosUsados.promocoes.indexOf(item);
          if (index !== -1) {
            this.filtrosUsados.promocoes.splice(index, 1);
          }
          this.focusCampo('promocoes');
          break;
        case "fabricantes":
          index = this.filtrosUsados.fabricantes.indexOf(item);
          if (index !== -1) {
            this.filtrosUsados.fabricantes.splice(index, 1);
          }
          this.$nextTick(() => {
            this.focusCampo(this.$refs.refFabricante); // Tentar focar no campo de texto
          });
          this.focusCampo('fabricantes');
          break;
        case "secoes":
          index = this.filtrosUsados.secoes.indexOf(item);
          if (index !== -1) {
            this.filtrosUsados.secoes.splice(index, 1);
          }
          this.focusCampo('secoes');
          break;
        case "produto":
          this.filtrosUsados.busca = ''
          this.buscaPersonalizada.busca = ''
          this.focusCampo('produto');
          break;
        case "data":
          this.buscaPersonalizada = {
            busca: this.buscaPersonalizada.busca,
            fabricantes:[],
            secoes:[],
            promocoes:[],
            dtini: this.buscaPersonalizada.dtini,
            dtfim: this.buscaPersonalizada.dtfim,
            tipo: this.buscaPersonalizada.tipo,
            idempresa: this.buscaPersonalizada.idempresa || this.usuario.idfilial,
            flagestoque: this.buscaPersonalizada.flagestoque,
          };
          break;
        default:
          this.filtrosUsados= {
            busca: "",
            tipo: 'G',
            fabricantes: [],
            secoes: [],
            promocoes: [],
            dtini: `${new Date().getFullYear()}-01-01`,
            dtfim: `${new Date(new Date())
                .toLocaleDateString("pt-BR")
                .split("/")
                .reverse()
                .join("-")}`,
            idempresa: this.usuario.idfilial,
            flagestoque: null,
          };
          this.buscaPersonalizada = {
            busca: '',
            fabricantes:[],
            secoes:[],
            promocoes:[],
            dtini: `${new Date().getFullYear()}-01-01`,
            dtfim: `${new Date(new Date())
                .toLocaleDateString("pt-BR")
                .split("/")
                .reverse()
                .join("-")}`,
            tipo: this.buscaPersonalizada.tipo,
            idempresa: this.buscaPersonalizada.idempresa || this.usuario.idfilial,
            flagestoque: this.buscaPersonalizada.flagestoque,
          };
          break;
      }
    },

    focusCampo(ref) {
      var campo;
      switch (ref) {
        case "promocoes":
          this.$nextTick(() => {
            campo = this.$refs.refPromocao;
            if (campo && campo.$el && campo.$el.offsetParent !== null) {
              campo.focus();
            } else {
              setTimeout(this.focusCampo('promocoes'), 100);
            }
          });
          break;
        case "fabricantes":
          this.$nextTick(() => {
            campo = this.$refs.refFabricante;
            if (campo && campo.$el && campo.$el.offsetParent !== null) {
              campo.focus();
            } else {
              setTimeout(this.focusCampo('fabricantes'), 100);
            }
          });
          break;
        case "secoes":
          this.$nextTick(() => {
            campo = this.$refs.refSecao;
            if (campo && campo.$el && campo.$el.offsetParent !== null) {
              campo.focus();
            } else {
              setTimeout(this.focusCampo('secoes'), 100);
            }
          });
          break;
        case "produto":
          this.$nextTick(() => {
            campo = this.$refs.refProduto;
            if (campo && campo.$el && campo.$el.offsetParent !== null) {
              campo.focus();
            } else {
              setTimeout(this.focusCampo('produto'), 100);
            }
          });
          break;
      }
    },

    async geraEtiquetasGeral() {
      this.dialogConfirma= false;
      this.carregandoPdfMensagem = `Aguarde, buscando dados de ${this.produtos.total} produtos`;
      this.carregandoPdf = true;
      await this.listarProdutosGeral();

      const dados = this.produtosGeral.lista;

      let agora = new Date();
      let ano = agora.getFullYear();
      let mes = ('0' + (agora.getMonth() + 1)).slice(-2); // +1 porque o mês começa do zero
      let dia = ('0' + agora.getDate()).slice(-2);

      for (let j = 0; j < Math.ceil(dados.length / 672); j++) {
        var linha = 1;
        const startIndex = j * 672;
        const endIndex = Math.min(startIndex + 672, dados.length);

        var htmlContent = `<!DOCTYPE html>
                          <html>
                          <head>
                              <title>Etiquetas</title>
                              <meta charset="UTF-8">
                              <meta http-equiv="Content-Type" content="text/html; charset=iso-8859-1">
                              <style>
                                  body {
                                      font-family: Arial, sans-serif;
                                  }

                                  .etiquetas-table {
                                      margin: 0 3mm 0 3mm !important;
                                      width: 204mm;
                                      border-collapse: separate;
                                      border-spacing: 6px 3px; /* Espaço entre colunas e linhas */
                                  }

                                  .etiqueta {
                                      border: 1px solid black !important;
                                      width: 33.33%;
                                      height: 100%;
                                      box-sizing: border-box;
                                  }

                                  .etiqueta table {
                                      width: 100%;
                                      border-collapse: collapse;
                                  }

                                  .etiqueta table td {
                                      padding: 8px;
                                      text-align: center;

                                  }

                                  .nome_produto {
                                      height:40px !important;
                                      text-transform: uppercase;
                                      text-align: left !important;
                                      font-size: 0.6em !important;
                                      padding: 2px !important;
                                  }
                                  .preco {
                                      width: 80px;
                                      text-align: left !important;
                                      padding: 0px !important;
                                  }
                                  .preco .titulo {
                                      font-size: 0.6em !important;
                                      font-weight: bold;
                                  }
                                  .preco .valor{
                                      font-size: 0.9em;
                                      font-weight: bold;
                                  }
                                  .preco_promocao {
                                      width: 80px;
                                      text-align: left !important;
                                      padding: 0px !important;
                                      vertical-align: top !important;
                                  }
                                  .preco_promocao .titulo {
                                      font-size: 0.6em !important;
                                      font-weight: bold;
                                  }
                                  .preco_promocao .valor{
                                      font-size: 0.9em;
                                      font-weight: bold;
                                  }
                                  .preco_promocao .titulo_promocao {
                                      font-size: 0.5em !important;
                                      font-weight: bold;
                                  }
                                  .preco_promocao .valor_promocao{
                                      font-size: 0.7em;
                                      font-weight: bold;
                                  }
                                  .codigo_barras_imagem {
                                      padding: 0px 2px !important;
                                  }

                                  .codigo_barras_imagem .imagem {
                                      width:160px;
                                      height:45px;
                                  }

                                  .codigo_barras_numero {
                                      font-size: 0.7em !important;
                                      padding: 0px !important;
                                  }

                                  .data {
                                      font-size: 0.4em !important;
                                      text-align: left !important;
                                      padding-top: 0px !important;
                                  }

                                  .numero {
                                      padding: 0px !important;
                                  }

                                  .numero div {
                                      display: inline-block !important;
                                      font-size: 0.6em !important;
                                      text-align: center !important;
                                      width:8px;
                                      padding: 0px !important;
                                      word-wrap: break-word !important;
                                  }

                                  .numero_titulo {
                                      text-align: right !important;
                                      vertical-align: bottom !important;
                                      font-size: 0.5em !important;
                                      width:1px;
                                      padding: 0px !important;
                                      padding-right: 2px !important;
                                  }

                                  .embalagem {
                                      vertical-align: top !important;
                                      font-size: 0.8em !important;
                                      width: 1px;
                                      padding: 0px !important;
                                      padding-top: 5px !important;
                                      padding-right: 5px !important;
                                      font-weight: bold;
                                  }

                                  .page-break {
                                      page-break-before: always;
                                      break-before: page;
                                  }
                              </style>
                          </head>
                          <body>
                          <div style="height: 4mm">&nbsp;</div>`;
        for (let i = startIndex; (i < endIndex); i += 3) {
          if (i + 3 < endIndex) {
            htmlContent += `
                  <div>
                      <table class="etiquetas-table">
                          <tbody>
                              <tr>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                          <tr>
                                              <td colspan="4" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i].base64}" />
                                              </td>
                                              <td class="numero" rowspan="3"><div>${dados[i].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i+1].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                          <tr>
                                              <td colspan="4" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i+1].base64}" />
                                              </td>
                                              <td class="numero" rowspan="3"><div>${dados[i+1].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i+1].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i+2].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                           <tr>
                                              <td colspan="4" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i+2].base64}" />
                                              </td>
                                              <td class="numero" rowspan="3"><div>${dados[i+2].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i+2].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>`;
          } else if (i + 1 < endIndex) {
            htmlContent += `
                  <div>
                      <table class="etiquetas-table">
                          <tbody>
                              <tr>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                          <tr>
                                              <td colspan="4" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i].base64}" />
                                              </td>
                                              <td class="numero" rowspan="3"><div>${dados[i].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i+1].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                          <tr>
                                              <td colspan="4" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i+1].base64}" />
                                              </td>
                                              <td class="numero" rowspan="3"><div>${dados[i+1].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i+1].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                                  <td></td>
                              </tr>
                          </tbody>
                      </table>
                  </div>`;
          } else {
            htmlContent += `
                  <div>
                      <table class="etiquetas-table">
                          <tbody>
                              <tr>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                          <tr>
                                              <td colspan="4" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i].base64}" />
                                              </td>
                                              <td class="numero" rowspan="3"><div>${dados[i].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                                  <td></td>
                                  <td></td>
                              </tr>
                          </tbody>
                      </table>
                  </div>`;
          }
          if ( (linha) != 1 && 0 == ((linha) % 8) && (i+3) < endIndex){
            htmlContent += `<div class="page-break">&nbsp;</div>
                                <div style="height: 3mm">&nbsp;</div>`;
          }
          linha++;
        }
        htmlContent+= `</body> </html>`;
        this.carregandoPdfMensagem = `Gerando arquivo PDF, parte ${j+1} de ${Math.ceil(dados.length / 672)}`;
        await this.geraArquivoFinal(htmlContent, (j+1),Math.ceil(dados.length / 672), agora);
        // console.log('Processando Arquivo PDF', j+1,'/',Math.ceil(dados.length / 672))
        // console.log(htmlContent)
        if ((j+1) == Math.ceil(dados.length / 672)) {
          this.carregandoPdf = false;
        }
      }
    },

    async geraEtiquetas() {
        this.dialogProdutosSelecionado=false;
        this.carregandoPdfMensagem = `Aguarde, armazenando histórico`;
        await this.gravaHistorico();
        const dados = this.selected.flatMap(item => Array(item.quantidade).fill(item));

        let agora = new Date();
        let ano = agora.getFullYear();
        let mes = ('0' + (agora.getMonth() + 1)).slice(-2); // +1 porque o mês começa do zero
        let dia = ('0' + agora.getDate()).slice(-2);

         for (let j = 0; j < Math.ceil(dados.length / 672); j++) {
            var linha = 1;
            const startIndex = j * 672;
            const endIndex = Math.min(startIndex + 672, dados.length);

            var htmlContent = `<!DOCTYPE html>
                          <html>
                          <head>
                              <title>Etiquetas</title>
                              <meta charset="UTF-8">
                              <meta http-equiv="Content-Type" content="text/html; charset=iso-8859-1">
                              <style>
                                  body {
                                      font-family: Arial, sans-serif;
                                  }

                                  .etiquetas-table {
                                      margin: 0 3mm 0 3mm !important;
                                      width: 204mm;
                                      border-collapse: separate;
                                      border-spacing: 6px 3px; /* Espaço entre colunas e linhas */
                                  }

                                  .etiqueta {
                                      border: 1px solid black !important;
                                      width: 33.33%;
                                      height: 100%;
                                      box-sizing: border-box;
                                  }

                                  .etiqueta table {
                                      width: 100%;
                                      border-collapse: collapse;
                                  }

                                  .etiqueta table td {
                                      padding: 8px;
                                      text-align: center;

                                  }

                                  .nome_produto {
                                      height:40px !important;
                                      text-transform: uppercase;
                                      text-align: left !important;
                                      font-size: 0.6em !important;
                                      padding: 2px !important;
                                  }
                                  .preco {
                                      width: 80px;
                                      text-align: left !important;
                                      padding: 0px !important;
                                  }
                                  .preco .titulo {
                                      font-size: 0.6em !important;
                                      font-weight: bold;
                                  }
                                  .preco .valor{
                                      font-size: 0.9em;
                                      font-weight: bold;
                                  }
                                  .preco_promocao {
                                      width: 80px;
                                      text-align: left !important;
                                      padding: 0px !important;
                                      vertical-align: top !important;
                                  }
                                  .preco_promocao .titulo {
                                      font-size: 0.6em !important;
                                      font-weight: bold;
                                  }
                                  .preco_promocao .valor{
                                      font-size: 0.9em;
                                      font-weight: bold;
                                  }
                                  .preco_promocao .titulo_promocao {
                                      font-size: 0.5em !important;
                                      font-weight: bold;
                                  }
                                  .preco_promocao .valor_promocao{
                                      font-size: 0.7em;
                                      font-weight: bold;
                                  }
                                  .codigo_barras_imagem {
                                      padding: 0px 2px !important;
                                  }

                                  .codigo_barras_imagem .imagem {
                                      width:160px;
                                      height:45px;
                                  }

                                  .codigo_barras_numero {
                                      font-size: 0.7em !important;
                                      padding: 0px !important;
                                  }

                                  .data {
                                      font-size: 0.4em !important;
                                      text-align: left !important;
                                      padding-top: 0px !important;
                                  }

                                  .numero {
                                      padding: 0px !important;
                                  }

                                  .numero div {
                                      display: inline-block !important;
                                      font-size: 0.6em !important;
                                      text-align: center !important;
                                      width:8px;
                                      padding: 0px !important;
                                      word-wrap: break-word !important;
                                  }

                                  .numero_titulo {
                                      text-align: right !important;
                                      vertical-align: bottom !important;
                                      font-size: 0.5em !important;
                                      width:1px;
                                      padding: 0px !important;
                                      padding-right: 2px !important;
                                  }

                                  .embalagem {
                                      vertical-align: top !important;
                                      font-size: 0.8em !important;
                                      width: 1px;
                                      padding: 0px !important;
                                      padding-top: 5px !important;
                                      padding-right: 5px !important;
                                      font-weight: bold;
                                  }

                                  .page-break {
                                      page-break-before: always;
                                      break-before: page;
                                  }
                              </style>
                          </head>
                          <body>
                          <div style="height: 4mm">&nbsp;</div>`;
            for (let i = startIndex; (i < endIndex); i += 3) {
             if (i + 3 < endIndex) {
               htmlContent += `
                  <div>
                      <table class="etiquetas-table">
                          <tbody>
                              <tr>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                          <tr>
                                              <td colspan="4" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i].base64}" />
                                              </td>
                                              <td class="numero" rowspan="3"><div>${dados[i].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i+1].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                          <tr>
                                              <td colspan="4" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i+1].base64}" />
                                              </td>
                                              <td class="numero" rowspan="3"><div>${dados[i+1].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i+1].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i+2].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                           <tr>
                                              <td colspan="4" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i+2].base64}" />
                                              </td>
                                              <td class="numero" rowspan="3"><div>${dados[i+2].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i+2].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>`;
             } else if (i + 1 < endIndex) {
               htmlContent += `
                  <div>
                      <table class="etiquetas-table">
                          <tbody>
                              <tr>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                          <tr>
                                              <td colspan="4" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i].base64}" />
                                              </td>
                                              <td class="numero" rowspan="3"><div>${dados[i].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i+1].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                          <tr>
                                              <td colspan="4" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i+1].base64}" />
                                              </td>
                                              <td class="numero" rowspan="3"><div>${dados[i+1].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i+1].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                                  <td></td>
                              </tr>
                          </tbody>
                      </table>
                  </div>`;
             } else {
               htmlContent += `
                  <div>
                      <table class="etiquetas-table">
                          <tbody>
                              <tr>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                          <tr>
                                              <td colspan="4" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i].base64}" />
                                              </td>
                                              <td class="numero" rowspan="3"><div>${dados[i].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                                  <td></td>
                                  <td></td>
                              </tr>
                          </tbody>
                      </table>
                  </div>`;
             }
             if ( (linha) != 1 && 0 == ((linha) % 8) && (i+3) < endIndex){
               htmlContent += `<div class="page-break">&nbsp;</div>
                                <div style="height: 3mm">&nbsp;</div>`;
             }
             linha++;
           }
            htmlContent+= `</body> </html>`;

           this.carregandoPdfMensagem = `Gerando arquivo PDF, parte ${j+1} de ${Math.ceil(dados.length / 672)}`;
           await this.geraArquivoFinal(htmlContent, (j+1),Math.ceil(dados.length / 672), agora);
           if ((j+1) == Math.ceil(dados.length / 672)) {
             this.carregandoPdf = false;
           }
          }
    },

    async gravaHistorico(){
      this.produtosHistorico = this.selected.map((v) => {
        return {
          tela: 'Deposito',
          referencia: 'Selecionados',
          idempresa: this.buscaPersonalizada.idempresa || null,
          idproduto: v.idproduto,
          idsubproduto: v.idsubproduto,
          preco: v.preco,
          precopromocao: v.precopromocao,
          idpromocao: v.idpromocao,
          dtinipromocao: v.dtinipromocao,
          dtfimpromocao: v.dtfimpromocao,
        };
      });
      return axios
        .post(`${this.backendUrl}etiqueta/historico/gravar`, {
          produtos: this.produtosHistorico
        })
        .then(() => {
        })
        .catch(() =>{
          this.carregando = false;
          this.carregandoPdf = false;
        });
    },

    verificaQuantidade(item) {
      const registroIndex = this.selected.findIndex(o => o.compositeKey === item.compositeKey);
      if (registroIndex !== -1 ) {
        this.selected[registroIndex].quantidade = item.quantidade;
        // console.log('atualização foi bem-sucedida')
      } else {
        // console.log('registro não foi encontrado')
      }

      this.qtdEtiquetas = this.selected.flatMap(item => Array(item.quantidade).fill(item)).length;
    },


    async geraArquivoFinal(htmlContent, i, f, agora){

      let dia = ('0' + agora.getDay()).slice(-2);
      let hora = ('0' + agora.getHours()).slice(-2);
      let minuto = ('0' + agora.getMinutes()).slice(-2);
      let segundos = ('0' + agora.getSeconds()).slice(-2);

      var nomeArquivo = `Etiquetas_parte_${i}_de_${f} (${dia}${hora}${minuto}${segundos}).pdf`;

      var opt = {
        margin: 0,
        filename: nomeArquivo,
        image: {type: "jpeg", quality: 1},
        html2canvas: {dpi: 192, letterRendering: true, scale: 2},
        jsPDF: {
          unit: "pt",
          format: "a4",
          orientation: "portrait",
        },
      };

      await html2pdf().from(htmlContent).set(opt).toPdf().get('pdf').save();
    },

    isItemSelected(item) {
      // this.selectedPrint.some(selectedItem => selectedItem.compositeKey === item.compositeKey)
      this.qtdEtiquetas = this.selected.flatMap(item => Array(item.quantidade).fill(item)).length;
      return this.selected.some(selectedItem => selectedItem.compositeKey === item.compositeKey);
    },

    listarProdutosGeral(pg) {
      this.pgSelecionada = pg;
      return axios
          .post(`${this.backendUrl}etiqueta/listar`, {
            busca: this.buscaPersonalizada.busca,
            idempresa: this.buscaPersonalizada.idempresa || this.usuario.idfilial,
            tipo: this.buscaPersonalizada.tipo,
            idpromocao: this.buscaPersonalizada.promocoes.join(",") || null,
            idsecao: this.buscaPersonalizada.secoes.join(",") || null,
            idfabricante: this.buscaPersonalizada.fabricantes.join(",") || null,
            dtini: this.buscaPersonalizada.dtini,
            dtfim: this.buscaPersonalizada.dtfim,
            limit: 100,
            offset: 100 * pg,
            flaggeral: true,
            flagestoque: this.buscaPersonalizada.flagestoque,
          })
          .then((res) => {
            if (res.data.lista != null) {
              this.produtosGeral.lista = res.data.lista == null ? [] : res.data.lista;
            } else {
              this.produtosGeral.lista = [];
            }
            this.produtosGeral.total = res.data.total;
          })
          .catch(() =>{
            this.carregando = false;
            this.carregandoPdf = false;
          });
    },

    listarProdutosPersonalizados(pg) {
      this.pgSelecionada = pg;
      this.carregando=true;
      return axios
          .post(`${this.backendUrl}etiqueta/listar`, {
            busca: this.buscaPersonalizada.busca,
            idempresa: this.buscaPersonalizada.idempresa || this.usuario.idfilial,
            tipo: 'G',
            idpromocao: this.buscaPersonalizada.promocoes.join(",") || null,
            idsecao: this.buscaPersonalizada.secoes.join(",") || null,
            idfabricante: this.buscaPersonalizada.fabricantes.join(",") || null,
            dtini: this.buscaPersonalizada.dtini,
            dtfim: this.buscaPersonalizada.dtfim,
            limit: 100,
            offset: 100 * pg,
            flaggeral: false,
            flagestoque: this.buscaPersonalizada.flagestoque
          })
          .then((res) => {
            // this.produtos = res.data == null ? [] : res.data;
            if (res.data.lista != null) {
              this.produtos.lista = res.data.lista == null ? [] : res.data.lista.map((v) => {
                const compositeKey = `${v.idsubproduto}-${(v.preco - v.precopromocao).toFixed(2)}-${(v.idpromocao)}`;
                const selectedProduto = this.selected.find(u => u.compositeKey === compositeKey);
                const quantidade = selectedProduto ? selectedProduto.quantidade : 1;
                return {
                  ...v,
                  compositeKey: compositeKey,
                  quantidade: quantidade,
                };
              });
            } else {
              this.produtos.lista = [];
            }
            this.produtos.total = res.data.total;
            this.carregando = false;
            this.primeiraBusca = true;

            this.filtrosUsados.busca = !this.buscaPersonalizada.busca ? '' : this.buscaPersonalizada.busca;
            this.filtrosUsados.idempresa = !this.buscaPersonalizada.idempresa ? this.usuario.idfilial : this.buscaPersonalizada.idempresa;
            this.filtrosUsados.dtini = !this.buscaPersonalizada.dtini ? '' : this.buscaPersonalizada.dtini;
            this.filtrosUsados.dtfim = !this.buscaPersonalizada.dtfim ? '' : this.buscaPersonalizada.dtfim;
            this.filtrosUsados.tipo = !this.buscaPersonalizada.tipo ? '' : this.buscaPersonalizada.tipo;
            this.filtrosUsados.fabricantes = this.buscaPersonalizada.fabricantes.length > 0 ? this.buscaPersonalizada.fabricantes : [];
            this.filtrosUsados.promocoes = this.buscaPersonalizada.promocoes.length > 0 ? this.buscaPersonalizada.promocoes : [];
            this.filtrosUsados.secoes = this.buscaPersonalizada.secoes.length > 0 ? this.buscaPersonalizada.secoes : [];
            this.filtrosUsados.flagestoque = this.buscaPersonalizada.flagestoque;
          })
          .catch(() =>{
            this.carregando = false;
            this.carregandoPdf = false;
          });
    },


    listarFiltros(tipo) {
      this.buscaPersonalizada.promocoes = [];
      this.filtrosEtiquetas = [];
      this.limpaFiltros(tipo);
      return axios
          .post(`${this.backendUrl}etiqueta/filtro/listar`, {
            idempresa: this.buscaPersonalizada.idempresa,
            dtini: this.buscaPersonalizada.dtini,
            dtfim: this.buscaPersonalizada.dtfim,
            tipo: this.buscaPersonalizada.tipo,
            flagestoque: this.buscaPersonalizada.flagestoque,
            busca: this.buscaPersonalizada.busca,
          })
          .then((res) => {
            this.filtrosEtiquetas = res.data.EtiquetaFiltro == null ? [] : res.data.EtiquetaFiltro;
            // this.modelos.Fabricantes = res.data.Fabricantes.map((v) => {
            //   return {
            //     idfabricante: v.idfabricante,
            //     fabricante: v.fabricante,
            //     fabricanteComp: `${v.idfabricante} - ${v.fabricante}`,
            //   };
            // });
          })
          .catch(() =>{
            this.carregando = false;
            this.carregandoPdf = false;
          });
    },


    async init() {
      await this.listarFiltros();
    },
  },
  mounted() {
    window.addEventListener('scroll', this.checkScrollPosition);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkScrollPosition);
  },
  created() {
    this.buscaPersonalizada.idempresa = this.usuario.idfilial;
    this.init();
  },
};
</script>

<style scoped>
.v-btn {
  transition: opacity 0.3s;
}

.badge-custom .v-icon {
  font-size: 11px !important; /* Ajuste esse valor conforme necessário */
}
.badge-custom .v-badge__badge {
  margin-right: -18px !important; /* Ajuste esse valor conforme necessário */
}


.pdf-container {
  padding: 0 !important;
  height: calc(100vh - 64px) !important; /* Altura total menos a altura da toolbar */
  overflow: hidden !important;
}

.pdf-container embed {
  width: 100% !important;
  height: 100% !important;
}

.v-dialog__content--active {
  overflow: hidden;
}

fieldset {
  padding: 8px;
  border: solid rgba(255, 255, 255, 0.93) 1px;
  border-radius: 4px;
}

fieldset legend {
  padding: 0px 5px;
  font-size: 0.8em;
}

</style>